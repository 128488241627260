import { useCallback, useRef, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import Restricted from "PermissionProvider/Restricted";
import { Link } from 'react-router-dom'
import SelectWithLabel from 'components/UI/SelectWithLabel'
import { useIiaFilters } from './hooks/useIiaFilters';
import { useIiaQuery } from './hooks/useIiaQuery';
import { useDebouncedPage } from './hooks/useDebouncedPage';
import { convert } from 'components/Common/CommonFunctions'

function IIAsList(props) {
  const initialState = {
    startYearSelected: null,
    endYearSelected: null,
    countrySelected: null,
    institutionSelected: null,
    agreementIdSelected: null,
    ewpIdSelected: null,
    localFinalizedSelected: null,
    statusSelected: null,
    ewpStatusSelected: null,
    departmentSelected: null,
    departmentalCoordinatorSelected: null,
    isDeletedSelected: "0",
    countriesList: [],
    departments: [],
    academicYears: [],
    institutionsList: [],
    departmentalCoordinatorsList: [],
    ewpStatusesList: []
  };

  const filters = useIiaFilters(initialState);
  const {
    state,
    setStartYearSelected,
    setEndYearSelected,
    setCountrySelected,
    setInstitutionSelected,
    setAgreementIdSelected,
    setEwpIdSelected,
    setStatusSelected,
    setLocalFinalizedSelected,
    setEwpStatusSelected,
    setDepartmentSelected,
    setDepartmentalCoordinatorSelected,
    setIsDeletedSelected
  } = filters;
  const {
    startYearSelected,
    endYearSelected,
    countrySelected,
    institutionSelected,
    agreementIdSelected,
    ewpIdSelected,
    statusSelected,
    ewpStatusSelected,
    localFinalizedSelected,
    departmentSelected,
    departmentalCoordinatorSelected,
    countriesList,
    ewpStatusesList,
    departments,
    academicYears,
    institutionsList,
    departmentalCoordinatorsList,
    isDeletedSelected
  } = state;
  const { agreements, loading, page, total, setPage } = useIiaQuery(filters, props.userService);
  const setPageDebounced = useCallback(useDebouncedPage((page) => setPage(page), 500), [])
  const topRef = useRef(null);

  useEffect(() => {
    document.title = props.title
  }, [])

  if (loading) {
    return (
      <div style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <Loader
          className="spinner mx-auto"
          type="TailSpin"
          color="#3A435E"
          height={70}
          width={70}
        />
      </div>
    )
  } else {
    let paginationItems = [];
    const pageLimit = 10;
    if (page === 0) {
      for (let i = 0; i < (Math.ceil(total / pageLimit) > 3 ? 3 : Math.ceil(total / pageLimit)); i++) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === page} onClick={(event) => { setPageDebounced(i); topRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
            {i + 1}
          </Pagination.Item>
        );
      }
    } else if (page === Math.ceil(total / pageLimit) - 1) {
      for (let i = Math.ceil(total / pageLimit) - 1; i >= Math.ceil(total / pageLimit) - 3 && i >= 0; i--) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === page} onClick={(event) => { setPageDebounced(i); topRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
            {i + 1}
          </Pagination.Item>
        );
        paginationItems.sort((element1, element2) => {
          if (element1.key < element2.key) return -1;
          if (element1.key > element2.key) return 1;
          return 0;
        })
      }
    } else {
      for (let i = page - 1; i < page + 2; i++) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === page} onClick={(event) => { setPageDebounced(i); topRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
            {i + 1}
          </Pagination.Item>
        );
      }
    }

    return (
      <div className="py-5" ref={topRef}>
        <div className="container">

          {/*<Restricted to="edit" fallback={<></>}>
            <div className="row">
              <div className="col-md-12 text-right">
                <button className="btn btn-next m-2" style={{ color: "white" }} onClick={() => setShowFilters(!showFilters)}>
                  <i className="fa fa-filter fa-fw"></i>
                  Filter
                </button>
              </div>
            </div>
          </Restricted>*/}

          <div className="row">


            <div className="col-md-3 bg-light pt-2 filter-sidebar mx-auto h-100">
              <div className="row">
                <div className="col-md-12">
                  <p className="font-weight-bold text-center">Filters</p>
                </div>

                <div className="form-group">
                  <SelectWithLabel
                    value={(countrySelected) ? countriesList.find(i => i.id === countrySelected).countryName : ""}
                    onSelectChange={(e) => {
                      setInstitutionSelected(null)
                      if (e !== null) {
                        setCountrySelected(e.value);
                      } else {
                        setCountrySelected(null);
                      }
                    }}
                    id="country"
                    placeholderText="Type country"
                    clearable={true}
                    listOptions={countriesList.map(i => ({ value: i.id, label: i.countryName }))}
                  >
                    Country of Partner Institution
                  </SelectWithLabel>
                </div>
                <div className="form-group">
                  <SelectWithLabel
                    disabled={countrySelected ? false : true}
                    value={((institutionSelected) ? institutionsList.find(i => i.id === institutionSelected).name : "")}
                    onSelectChange={(e) => {

                      if (e !== null) {
                        setInstitutionSelected(e.value)
                      }
                      else {
                        setInstitutionSelected(null)
                      }
                    }}
                    id="institution"
                    placeholderText="Enter name of the institution"
                    clearable={true}
                    listOptions={institutionsList.map(i => ({ value: i.id, label: i.name + " (" + i.erasmusCode + ")" }))}
                  >
                    Name of the Partner Institution
                  </SelectWithLabel>
                </div>
                <div className="form-group">
                  <label>Agreement ID</label>
                  <input pattern="[0-9]*" type="number" value={agreementIdSelected} className="form-control" id="agreement-id" onChange={(e) => {
                    if (e !== null) {
                      setAgreementIdSelected(e.target.value)
                    }
                    else {
                      setAgreementIdSelected(null)
                    }
                  }} onKeyDown={e => {
                    const exceptThisSymbols = ["e", "E", "+", "-", ".", ","];
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }} />
                </div>
                <div className="form-group">
                  <label>EWP ID</label>
                  <input type="text" value={ewpIdSelected} className="form-control" id="agreement-id" onChange={(e) => {
                    if (e !== null) {
                      setEwpIdSelected(e.target.value)
                    }
                    else {
                      setEwpIdSelected(null)
                    }
                  }} onKeyDown={e => {
                    const exceptThisSymbols = ["e", "E", "+", "-", ".", ","];
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }} />
                </div>
                <div className="form-group">
                  <SelectWithLabel
                    value={statusSelected ? (statusSelected === 1 ? "Draft" : statusSelected === 2 ? "Submitted" : "Final") : ""}
                    onSelectChange={(e) => {
                      if (e != null) {
                        setStatusSelected(e.value)
                      }
                      else {
                        setStatusSelected(null)
                      }
                    }}
                    id="status"
                    clearable={true}
                    listOptions={[{ value: 1, label: "Draft" }, { value: 2, label: "Submitted" }, { value: 3, label: "Final" }]}
                  >
                    Status
                  </SelectWithLabel>
                </div>
                <div className="form-group">
                  <SelectWithLabel
                    value={ewpStatusSelected ? ewpStatusesList.find(i => i.id === ewpStatusSelected).name : ''}
                    onSelectChange={(e) => {
                      if (e != null) {
                        setEwpStatusSelected(e.value)
                      }
                      else {
                        setEwpStatusSelected(null)
                      }
                    }}
                    id="ewp-status"
                    clearable={true}
                    listOptions={ewpStatusesList.map(i => ({ value: i.id, label: i.name }))}
                  >
                    EWP Status
                  </SelectWithLabel>
                </div>
                <div className="form-group">
                  <SelectWithLabel
                    value={localFinalizedSelected === "0" ? 'False' : localFinalizedSelected === "1" ? 'True' : ''}
                    onSelectChange={(e) => {
                      if (e != null) {
                        console.log(e.value)
                        setLocalFinalizedSelected(e.value)
                      }
                      else {
                        setLocalFinalizedSelected(null)
                      }
                    }}
                    id="localFinalized"
                    clearable={true}
                    listOptions={[{ value: "0", label: "False" }, { value: "1", label: "True" }]}
                  >
                    Local Finalized
                  </SelectWithLabel>
                </div>
                <div className="form-group">
                  <SelectWithLabel
                    value={startYearSelected}
                    onSelectChange={(e) => {
                      if (e != null) {
                        setStartYearSelected(e.label)
                      }
                      else {
                        setStartYearSelected(null)
                      }
                    }}
                    id="startYear"
                    isOptionDisabled={(option) => option.value > new Date().getUTCFullYear() + 1}
                    clearable={true}
                    listOptions={academicYears.map(i => ({ value: i.id, label: i.id }))}
                  >
                    Start Year
                  </SelectWithLabel>
                </div>
                <div className="form-group">
                  <SelectWithLabel
                    value={endYearSelected}
                    onSelectChange={(e) => {
                      if (e != null) {
                        setEndYearSelected(e.label)
                      }
                      else {
                        setEndYearSelected(null)
                      }
                    }}
                    id="endYear"
                    clearable={true}
                    listOptions={academicYears.map(i => ({ value: i.id, label: i.id }))}
                  >
                    End Year
                  </SelectWithLabel>
                </div>
                <Restricted to="iia_edit_iro" fallback={<></>}>
                  <div className="form-group">
                    <SelectWithLabel
                      value={departmentSelected ? departments.find(i => i.id === departmentSelected).name : ""}
                      onSelectChange={(e) => {
                        setDepartmentalCoordinatorSelected(null)
                        if (e != null) {
                          setDepartmentSelected(e.value)
                        }
                        else {
                          setDepartmentSelected(null)
                        }
                      }}
                      id="department"
                      placeholderText="Type department"
                      clearable={true}
                      listOptions={departments.map(i => ({ value: i.id, label: i.name }))}
                    >
                      Department of the Local Institution
                    </SelectWithLabel>
                  </div>
                  <div className="form-group">
                    <SelectWithLabel
                      disabled={departmentSelected ? false : true}
                      value={departmentalCoordinatorSelected ? (departmentalCoordinatorsList.find(i => i.id === departmentalCoordinatorSelected).familyName + " " + departmentalCoordinatorsList.find(i => i.id === departmentalCoordinatorSelected).givenName) : ""}
                      onSelectChange={(e) => {
                        if (e != null) {
                          setDepartmentalCoordinatorSelected(e.value)
                        }
                        else {
                          setDepartmentalCoordinatorSelected(null)
                        }
                      }}
                      id="departmentalCoordinator"
                      placeholderText="Family Name e.g. Papadopoulos"
                      clearable={true}
                      listOptions={departmentalCoordinatorsList.map(i => ({ value: i.id, label: i.familyName + " " + i.givenName }))}
                    >
                      Departmental Coordinator of the Local Institution
                    </SelectWithLabel>
                  </div>
                  <div className="form-group">
                    <SelectWithLabel
                      value={isDeletedSelected === "0" ? 'No' : isDeletedSelected === "1" ? 'Yes' : ''}
                      onSelectChange={(e) => {
                        if (e != null) {
                          console.log(e.value)
                          setIsDeletedSelected(e.value)
                        }
                        else {
                          setIsDeletedSelected(null)
                        }
                      }}
                      id="localFinalized"
                      clearable={false}
                      listOptions={[{ value: "0", label: "No" }, { value: "1", label: "Yes" }]}
                    >
                      Show Deleted IIAs
                    </SelectWithLabel>
                  </div>
                </Restricted>
              </div>
            </div>
            <div className="col-md-9 pl-3 mx-auto">
              {(agreements.length) ? <div id="no-more-tables">
                <table className="table-striped table-condensed cf table-responsive">
                  <thead className="cf" style={{ backgroundColor: "#3A435E", color: "white" }}>
                    <tr>
                      <th scope="col" className="col-md-1">ID</th>
                      <th scope="col" className="col-md-1">Partner University</th>
                      <th scope="col" className="col-md-1">Local Departmental Coordinator</th>
                      <th scope="col" className="col-md-1">Local Department</th>
                      <th scope="col" className="col-md-1">Year</th>
                      <th scope="col" className="col-md-1">Status</th>
                      <th scope="col" className="col-md-1">EWP Status</th>
                      <th scope="col" className="col-md-1">Date Created</th>
                      <th scope="col" className="col-md-1">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agreements.map((i, idx) => {
                      var localDepartmentalCoordinator = i.departmentalCoordinatorContacts.find(j => j?.department && j?.department.institution.isLocal === true && j.roles?.find(j => j?.value === JSON.parse(process.env.REACT_APP_DEPARTMENTAL_COORDINATOR_ROLE).value))
                      return (
                        <tr>
                          <td key={"ID_" + i.id} className="text-center" data-title="No">{i.id}</td>
                          <td key={"Partner_University_" + idx} data-title="Partner University">{i.institutions.filter(j => j.isLocal !== true) && i.institutions.filter(j => j.isLocal !== true).map(j => (<>{convert(j.name) + " (" + j.erasmusCode + ")"}<br /></>))}</td>
                          <td key={"Local_Departmental_Coordinator_" + idx} data-title="Local Departmental Coordinator">{localDepartmentalCoordinator && (localDepartmentalCoordinator.givenName + " " + localDepartmentalCoordinator.familyName)}</td>
                          <td key={"Local_Department_" + idx} data-title="Local Department">{localDepartmentalCoordinator && localDepartmentalCoordinator?.department.name}</td>
                          <td key={"Year_" + idx} data-title="Year">{(i.startYear && 'id' in i.startYear ? i.startYear.id : "") + " - " + (i.endYear && 'id' in i.endYear ? i.endYear.id : "")}</td>
                          <td key={"Status_" + idx} data-title="Status">{i.status.name}</td>
                          <td key={"Ewp_Status_" + idx} data-title="EWP Status">{i?.ewpStatus?.name ?? '-'}</td>
                          <td key={"Date_Created_" + idx} data-title="Date Created">{i.dateCreated ? (i.dateCreated.getDate() + "-" + (i.dateCreated.getMonth() + 1) + "-" + i.dateCreated.getFullYear()) : "Unknown"}</td>
                          <td key={"Actions_" + idx} data-title="Actions">
                            <div style={{ display: "flex" }}>
                              <Link to={"/iia/view/" + i.id}><i className='fa fa-eye fa-primary m-2'></i></Link>&nbsp;
                              <Restricted to="iia_edit_iro" fallback={i.status.id === 1 && <Link to={"/iia/edit/" + i.id}><i className='fa fa-pencil fa-primary m-2'></i></Link>}>
                                <Link to={"/iia/edit/" + i.id}><i className='fa fa-pencil fa-primary m-2'></i></Link>
                              </Restricted>
                            </div>
                          </td>
                        </tr>)
                    })}
                  </tbody>
                </table>
              </div> : <div className="d-flex justify-content-center">
                <p>No Inter-Institutional Agreements found</p>
              </div>}
              {total !== 0 && <div className=" ml-3 mb-4">Total number of agreements: {total}</div>}
              {
                total !== 0
                  ?
                  <div className="d-flex justify-content-center">
                    {/* feature idea do not show previous button if the user is in the first or last page etc */}
                    <Pagination>
                      <Pagination.First onClick={() => { if (total !== 0) setPageDebounced(0); topRef.current.scrollIntoView({ behavior: 'smooth' }) }} />
                      <Pagination.Prev onClick={() => { if (page !== 0) setPageDebounced(page - 1); topRef.current.scrollIntoView({ behavior: 'smooth' }) }} />
                      {paginationItems.length > 3 ? <Pagination.Ellipsis /> : <></>}
                      {paginationItems}
                      {paginationItems.length > 3 ? <Pagination.Ellipsis /> : <></>}
                      <Pagination.Next onClick={() => { if (page !== Math.ceil(total / pageLimit) - 1) setPageDebounced(page + 1); topRef.current.scrollIntoView({ behavior: 'smooth' }) }} />
                      <Pagination.Last onClick={() => { setPageDebounced(Math.ceil(total / pageLimit) - 1); topRef.current.scrollIntoView({ behavior: 'smooth' }) }} />
                    </Pagination>
                  </div>
                  :
                  <></>
              }
            </div>

          </div>

        </div>
      </div>
    )
  }
}

export default IIAsList;