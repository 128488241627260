import { useState, useEffect } from 'react';
import { ReactDataContext } from '@themost/react';
import { Tab, Nav, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Loader from "react-loader-spinner";
import InitialAgreementDetails from 'components/Iia/Form/components/InitialAgreementDetails';
import EditContactDetails from 'components/Iia/Form/components/EditContactDetails';
import StudentMobility from 'components/Iia/Form/components/StudentMobility';
import StaffMobility from 'components/Iia/Form/components/StaffMobility';
import 'styles/css/IIAform.css';
import UserService from "services/UserService";
import Restricted from 'PermissionProvider/Restricted';
import EditIIAComments from 'components/Iia/Form/components/EditIIAComments';
import { getOunitsByHeiIdEndpoint, getHeiByHeiIdEndpoint, notifyPartnerCNR, approveIIA, isApproveButtonDisabled, getLocalIIACNRList } from 'components/Common/CommonFunctions';
// import { SET_AUTH_DEPARTMENTS_ACTION } from '../ActiveList/reducers/activeIiaInitialFiltersReducer';


function Form(props) {
    const [loader, setLoader] = useState(true);
    const [mobilityID, setMobilityID] = useState(props.match.params ? Number(props.match.params.agreementId) : null);
    const [agreement, setAgreement] = useState(null);
    const [step, setStep] = useState(1);
    const [isced, setIsced] = useState([]);
    const [countries, setCountries] = useState([]);
    const [academicYears, setAcademicYears] = useState([]);
    const [localDepartmentList, setLocalDepartmentList] = useState(null);
    const [partnerDepartmentList, setPartnerDepartmentList] = useState(null);
    const [localContactList, setLocalContactList] = useState(null);
    const [partnerContactList, setPartnerContactList] = useState(null);
    const [localIiaCnrList, setLocalIiaCnrList] = useState(null);

    const history = useHistory();

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(UserService.getToken());

    useEffect(() => {
        document.title = props.title
    }, [])


    const setLocalDepartmentAndLocalContactsLists = async (institution) => {
        let tempContactList = [];
        let tempDepartmentList = [];

        const requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: new Headers({
                'Authorization': 'Bearer ' + UserService.getToken(),
            })
        };

        try {
            const localHei = await fetch(getHeiByHeiIdEndpoint(institution.heiId), requestOptions);
            const result = await localHei.json();

            if (result && result.developerMessage) {
                console.log("developer message ", result.developerMessage);
                return;
            }

            if (result && result.hei && result.hei.length > 0 && result.hei[0].ounitId) {
                const ounitId = result.hei[0].ounitId;
                for (var id in ounitId) {
                    try {
                        const response1 = await fetch(getOunitsByHeiIdEndpoint(institution.heiId, ounitId[id]), requestOptions);
                        const result1 = await response1.json();

                        if (result1 && result1.ounit && result1.ounit.length > 0 && result1.ounit[0].name) {

                            tempDepartmentList.push({
                                "name": result1.ounit[0].name[0].value,
                                "institution": institution,
                                "ounitId": result1.ounit[0].ounitId
                            });

                            if (result1.ounit[0].contact) {
                                for (var c in result1.ounit[0].contact) {
                                    tempContactList.push({
                                        "email": result1.ounit[0].contact[c].email.length > 0 ? result1.ounit[0].contact[c].email[0] : null,
                                        "givenName": result1.ounit[0].contact[c].personGivenNames.length > 0 ? result1.ounit[0].contact[c].personGivenNames[0].value : null,
                                        "familyName": result1.ounit[0].contact[c].personFamilyName.length > 0 ? result1.ounit[0].contact[c].personFamilyName[0].value : null,
                                        "phone": result1.ounit[0].contact[c].phoneNumber.length > 0 ? result1.ounit[0].contact[c].phoneNumber[0]?.e164 : null,
                                        "address": null,
                                        "department": {
                                            "name": result1.ounit[0].name[0].value,
                                            "institution": institution,
                                            "ounitId": result1.ounit[0].ounitId
                                        }
                                    });
                                }
                            }
                        }

                    } catch (error) {
                        console.log('error', error)
                    }
                }
            }

        } catch (error) {
            console.log('error', error)
        }
        //push teep in department list
        tempDepartmentList.push(JSON.parse(process.env.REACT_APP_LOCAL_TEEP_DEPARTMENT));

        setLocalDepartmentList(tempDepartmentList);
        setLocalContactList(tempContactList);
    }

    const setPartnerDepartmentandContactLists = async (iia) => {
        if (iia.institutions) {
            let tempContactList = [];
            let tempDepepartmentList = [];
            let institution = iia.institutions.find(i => i.isLocal === false);
            var requestOptions = {
                method: 'GET',
                redirect: 'follow',
                headers: new Headers({
                    'Authorization': 'Bearer ' + UserService.getToken(),
                })
            };
            try {
                const response = await fetch(getHeiByHeiIdEndpoint(institution.heiId), requestOptions);
                const result = await response.json();
                if (result && result.developerMessage) {
                    console.log("developerMessage", result.developerMessage)
                }
                if (result && result.hei && result.hei.length > 0 && result.hei[0].ounitId) {
                    const ounitId = result.hei[0].ounitId
                    for (var id in ounitId) {
                        try {
                            const response1 = await fetch(getOunitsByHeiIdEndpoint(institution.heiId, ounitId[id]), requestOptions)
                            const result1 = await response1.json()
                            if (result1 && result1.ounit && result1.ounit.length > 0 && result1.ounit[0].name);
                            {
                                tempDepepartmentList.push({
                                    "name": result1.ounit[0].name[0].value,
                                    "institution": institution,
                                    "ounitId": result1.ounit[0].ounitId
                                });
                                if (result1.ounit[0].contact) {
                                    for (var c in result1.ounit[0].contact) {
                                        tempContactList.push({
                                            "email": result1.ounit[0].contact[c].email.length > 0 ? result1.ounit[0].contact[c].email[0] : null,
                                            "givenName": result1.ounit[0].contact[c].personGivenNames.length > 0 ? result1.ounit[0].contact[c].personGivenNames[0].value : null,
                                            "familyName": result1.ounit[0].contact[c].personFamilyName.length > 0 ? result1.ounit[0].contact[c].personFamilyName[0].value : null,
                                            "phone": result1.ounit[0].contact[c].phoneNumber.length > 0 ? result1.ounit[0].contact[c].phoneNumber[0]?.e164 : null,
                                            "address": null,
                                            "department": {
                                                "name": result1.ounit[0].name[0].value,
                                                "institution": institution,
                                                "ounitId": result1.ounit[0].ounitId
                                            }
                                        });
                                    }
                                }
                            }
                        } catch (error) {
                            console.log('error', error)
                        }
                    }
                }
            } catch (error) {
                console.log('error', error)
            }
            setPartnerDepartmentList(tempDepepartmentList);
            setPartnerContactList(tempContactList)
        }
    }

    const fetchUserMe = async () => {
        try {
            return await context.model(`Users/Me`).asQueryable().getItem();
        } catch (err) {
            console.log(err);
        }
    }

    const fetchDepartmentalByUser = async (user) => {

        // αν ο συνδεμενος χρήστης ειναι IRO δεν αναζητουμε τον local departmental

        // αν ειναι Faculty
        if (user && JSON.parse(localStorage.getItem("role")).role === "Faculty") {
            try {
                const localDepartmental = await context.model(`MobilityContacts`).asQueryable().where('uid').equal(user.id).getItem();
                if (!localDepartmental.roles.find(i => i.value === JSON.parse(process.env.REACT_APP_DEPARTMENTAL_COORDINATOR_ROLE).value)) {
                    localDepartmental["roles"].push(JSON.parse(process.env.REACT_APP_DEPARTMENTAL_COORDINATOR_ROLE));
                }
                return localDepartmental;
            } catch (err) {
                console.log(err);
            }
        }
    }

    const fetchInitialData = async () => {
        try {
            const mobilitySubjectAreaResponse = await context.model(`MobilitySubjectAreas`).asQueryable().take(-1).getItems();
            setIsced(mobilitySubjectAreaResponse);
        } catch (err) {
            console.log(err);
        }

        try {
            const mobilityCountriesResponse = await context.model(`MobilityCountries`).asQueryable().take(-1).getItems();
            // remove United Kingdom: we do not make agreements with UK
            setCountries(mobilityCountriesResponse.filter(i => i.countryName !== "United Kingdom"));
        } catch (err) {
            console.log(err);
        }

        try {
            const academicYearsResult = await context.model(`AcademicYears`).asQueryable().where('id').greaterOrEqual(2014).and('id').lowerThan(2030).take(-1).getItems();
            setAcademicYears(academicYearsResult);
        } catch (err) {
            console.log(err);
        }

        if (mobilityID) {
            try {
                const agreement = await context.model(`MobilityAgreements`).asQueryable().where("id").equal(mobilityID).getItem();
                if (!agreement.institutions) {
                    console.log("SHOULD NEVER HERE...SHOULD NEVER HERE...SHOULD NEVER HERE...SHOULD NEVER HERE...");
                    // get local inst
                    const localInstitution = await context.model(`MobilityInstitutions`).asQueryable()
                        .where("heiId").equal(process.env.REACT_APP_LOCAL_HEI_ID).getItem();
                    agreement["institutions"] = [localInstitution]
                }
                let institution = agreement["institutions"].find(i => i.isLocal === true);
                setLocalDepartmentAndLocalContactsLists(institution); //removed await to fetch local departments and contacts asynchronous
                //if user is faculty append role 'Departmental Coordinator'
                if (JSON.parse(localStorage.getItem("role")).role === "Faculty") {

                    const user = await fetchUserMe();
                    const localDepartmental = await fetchDepartmentalByUser(user);
                    agreement["departmentalCoordinatorContacts"] = agreement["departmentalCoordinatorContacts"].filter(i => i.id !== localDepartmental.id)
                    if (localDepartmental) {
                        agreement["departmentalCoordinatorContacts"].push(localDepartmental);
                        agreement["owner"] = user;
                    }
                }
                await setAgreement(agreement);

                setTimeout(() => {
                    setLoader(false);
                }, 1000);
            } catch (err) {
                console.log(err);
            }

        } else {

            try {

                // get local inst
                const localInstitution = await context.model(`MobilityInstitutions`).asQueryable()
                    .where("heiId").equal(process.env.REACT_APP_LOCAL_HEI_ID).getItem();

                // get local factsheet
                const localFactsheet = await context.model(`MobilityFactsheets`).asQueryable()
                    .where("institution").equal(localInstitution.id).and("isActive").equal(true).getItem();

                const newAgreement = {
                    "institutions": [
                        localInstitution
                    ],
                    "factsheets": [
                        {
                            "id": localFactsheet.id,
                            "institution": localInstitution
                        }
                    ],
                    "createdAtMobisisApp": true
                };


                const user = await fetchUserMe();
                const localDepartmental = await fetchDepartmentalByUser(user);

                if (localDepartmental) {
                    newAgreement["departmentalCoordinatorContacts"] = [localDepartmental];
                    newAgreement["owner"] = user;
                }
                await setAgreement(newAgreement);

                setLocalDepartmentAndLocalContactsLists(localInstitution); //removed await to fetch local departments and contacts asynchronous

                setTimeout(() => {
                    setLoader(false);
                }, 1000);

            } catch (err) {
                console.log(err);
            }
        }
        try {
            const localIIACNRList = await getLocalIIACNRList(context, agreement?.id);
            setLocalIiaCnrList(localIIACNRList);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const removeIdFromObject = (object) => {
        if (!(typeof object === 'object' && object !== null)) {
            return;
        }

        if (object.hasOwnProperty('id')) {
            delete object.id;
        }

        if (Array.isArray(object)) {
            object.forEach(element => removeIdFromObject(element))
            return;
        }

        for (const key in object) {
            removeIdFromObject(object[key])
        }
    }

    const removeUidFromContactObject = (object) => {
        if (!(typeof object === 'object' && object !== null)) {
            return;
        }

        if (object.hasOwnProperty('uid')) {
            delete object.uid;
        }

        if (Array.isArray(object)) {
            object.forEach(element => removeUidFromContactObject(element))
            return;
        }

        for (const key in object) {
            removeUidFromContactObject(object[key])
        }
    }


    const cleanPropertiesOfFactsheetsBeforePost = (factsheets) => {
        if (!(Array.isArray(factsheets) && factsheets.length > 0)) {
            return;
        }
        factsheets.forEach(function (factsheet) {
            for (const property in factsheet) {
                if (property !== 'id' && property !== 'institution' && property !== 'isActive') {
                    delete factsheet[property];
                }
            }
        });
    }

    const redirectUserAfterChosenActionIsExecuted = (agreementId) => {
        if (props.match.path.includes('edit') || !agreementId) {
            setStep(1);
            setLoader(false);
        } else {
            history.push(`/iia/view/${agreementId}`);
        }
    }

    const saveIIAAsDraft = async () => {
        const departmantalCoordinatorExistsForPartners = await checkLocalDepartmantalCoordinatorExists();
        if (!departmantalCoordinatorExistsForPartners) {
            alert("You have to provide a local departmental coordinator");
        }
        else {
            const agreementId = await saveIIA("Draft");
            redirectUserAfterChosenActionIsExecuted(agreementId);
        }
    }

    const checkIfLocalInstitutionCoordinatorInListAndAppend = async () => {
        const allDepartmentalCoordinators = agreement?.departmentalCoordinatorContacts;
        const localDepartmentalCoordinators = agreement.departmentalCoordinatorContacts ? agreement.departmentalCoordinatorContacts.filter(i => i?.department?.institution.isLocal) : [];

        if (!localDepartmentalCoordinators.find(i => i.id === Number(process.env.REACT_APP_LOCAL_INSTITUTIONAL_ID))) {
            try {
                const localInstitutionalCoordinator = await context.model(`MobilityContacts`).where('id').equal(process.env.REACT_APP_LOCAL_INSTITUTIONAL_ID).getItem();
                agreement["departmentalCoordinatorContacts"] = [localInstitutionalCoordinator].concat(allDepartmentalCoordinators);

            }
            catch (err) {
                console.log('err', err)
            }

        }
    }

    const checkLocalDepartmantalCoordinatorExists = async () => {
        const allDepartmentalCoordinators = agreement?.departmentalCoordinatorContacts;
        if (allDepartmentalCoordinators?.length>0){
                let exists = agreement.departmentalCoordinatorContacts.find(k => k?.department?.institution?.heiId === process.env.REACT_APP_LOCAL_HEI_ID &&  k?.roles?.find(j => j?.value === JSON.parse(process.env.REACT_APP_DEPARTMENTAL_COORDINATOR_ROLE).value));
                if(!exists){
                    return false;
                } 
        }
        return true;
    }


    const createUserAndContact = async (localDepartmental) => {
        try {
            let usernameFromEmail = localDepartmental?.email.split("@")

            const userJSON = {
                name: usernameFromEmail[0],
                groups: [{ name: "Instructors" }]


            }
            console.log("userJSON ", userJSON)
            const user = await context.model('Users').save(userJSON);
            // new user has been created
            console.log("User: ", user);

            const contactJSON = {
                givenName: localDepartmental?.givenName,
                familyName: localDepartmental?.familyName,
                email: localDepartmental?.email,
                department: localDepartmental?.department,
                uid: user.id
            }

            await context.model('MobilityContacts').save(contactJSON);
            return user;

        } catch (err) {
            console.log(err);
        }
    }

    const getOwnerId = async (agreement) => {
        const localDepartmental = agreement?.departmentalCoordinatorContacts?.find(i => i?.department?.institution?.isLocal && i?.roles.find(j => j?.value === JSON.parse(process.env.REACT_APP_DEPARTMENTAL_COORDINATOR_ROLE).value));
        const owner = agreement.owner;
        if (localDepartmental && owner && localDepartmental.uid === owner.id) {
            return owner.id;
        } else if (localDepartmental && owner && localDepartmental.uid !== owner.id) {
            try {
                const contact = await context.model(`MobilityContacts`).asQueryable()
                    .where("email").equal(localDepartmental.email)
                    .and("familyName").equal(localDepartmental.familyName)
                    .and("givenName").equal(localDepartmental.givenName).getItem();
                console.log("contact ", contact);
                if (contact && contact.uid) {
                    return contact.uid;
                } else {
                    // TODO vivi
                    // create new User and new MobilityContact
                    const newUser = await createUserAndContact(localDepartmental);
                    return newUser.id;
                }
            } catch (err) {
                console.log(err);
            }
        } else if (!localDepartmental || !owner) {
            // TODO vivi
            // create new User and new MobilityContact
            if (JSON.parse(localStorage.getItem("role")).role === "Faculty") {
                const user = await fetchUserMe();
                return user.id;
            }
            else {
                const newUser = await createUserAndContact(localDepartmental);
                return newUser.id;
            }
        }
        return null;
    }

    const saveIIAAsSubmitted = async () => {
        const departmantalCoordinatorExistsForPartners = await checkLocalDepartmantalCoordinatorExists();
        if (!departmantalCoordinatorExistsForPartners) {
            alert("You have to provide a local departmental coordinator");
        }
        else {
            const agreementId = await saveIIA("Submitted");
            redirectUserAfterChosenActionIsExecuted(agreementId);
        }
    }

    const saveIIAAsFinalAndNotifyPartner = async () => {
        //TODO auth signer signings
        /*var m = new Date();
                var dateString = m.getFullYear() +"-"+ m.getMonth() +"-"+ m.getDay()
                    + "T" + m.getHours()+ ":" + m.getMinutes() + ":" + m.getSeconds() + "." + m.getMilliseconds() + "Z";
                agreement["dateModified"] = dateString;
                */
        const departmantalCoordinatorExistsForPartners = await checkLocalDepartmantalCoordinatorExists();
        if (!departmantalCoordinatorExistsForPartners) {
            alert("You have to provide a local departmental coordinator");
        }
        else {
            await saveIIA("Final");
            await notifyPartnerCNR(agreement.id);
            redirectUserAfterChosenActionIsExecuted(agreement.id);
        }
    }

    const saveIIA = async (statusName) => {
            console.log("agreement before save ", agreement);
            // fix agreement before posting
            // delete unneeded properties. properties that are deleted will nor return from the later post, so be careful
        agreement["isDeleted"] = 0; // undo delete on iia edit
        agreement["terminated"] = 0;

            // remove ids from objects:
            removeIdFromObject(agreement["ewpStatus"]);
            removeIdFromObject(agreement["partnerAgreementIdentifications"]);
            removeIdFromObject(agreement["signings"]);
            removeIdFromObject(agreement["staffMobilitiesForTraining"]);
            removeIdFromObject(agreement["staffMobilitiesForTeaching"]);
            removeIdFromObject(agreement["studentMobilitiesForTraineeship"]);
            removeIdFromObject(agreement["studentMobilitiesForStudies"]);
            removeIdFromObject(agreement["institutions"]);

            //remove uid to fix contact not saving

            await checkIfLocalInstitutionCoordinatorInListAndAppend();
            
            removeUidFromContactObject(agreement["departmentalCoordinatorContacts"]);

            // set dateModified, e.g. "2022-06-02T07:00:55.922Z"
            var m = new Date();
            var dateString = m.getFullYear() + "-" + m.getMonth() + "-" + m.getDay()
                + "T" + m.getHours() + ":" + m.getMinutes() + ":" + m.getSeconds() + "." + m.getMilliseconds() + "Z";
            agreement["dateModified"] = dateString;

            // keep id if any and institutions
            cleanPropertiesOfFactsheetsBeforePost(agreement["factsheets"]);

            // set new status
            agreement["status"] = {
                "name": statusName
            };

            await setLoader(true);

            try {
                //
                const owner = await getOwnerId(agreement);
                console.log(owner)
                agreement.owner = { "id": owner };
                const result = await context.model(`MobilityAgreements`).save(agreement);
                alert("Agreement saved successfully");

                if (shouldFixFactsheets(result)) {
                    // fix factsheets list
                    const result3 = await postFixedFactsheets(result);
                    result.factsheets = result3.factsheets;
                }

                await setAgreement(result);
                console.log("agreement after saved ", agreement);
                return result.id;
            } catch (err) {
                console.log(err);
            }
        
    }

    /**
     * αν ειναι η πρωτη φορα που γινεται save το iia,
     *          φερε το factsheet του partner. Αν δεν υπαρχει στο mobisis δημιούργησέ το.
     * αν δεν ειναι η πρώτη φορά που γινεται save το iia και ο user αλλαξε τo institution του partner,
     *          διορθωσε το factsheet του partner. Αν δεν υπαρχει στο mobisis δημιούργησέ το.
     * @param agreement
     * @returns {Promise<void>}
     */
    const postFixedFactsheets = async (agreement) => {
        console.log("hi from postFixedFactsheets");
        let newFactsheets = [];

        for (let i of agreement.institutions) {
            // δες αν δεν υπαρχει ήδη το factsheet στο mobisis
            const institution = await context.model(`MobilityInstitutions`).asQueryable()
                .where("id").equal(i.id).getItem();
            const factsheet = await context.model(`MobilityFactsheets`).asQueryable()
                .where("institution").equal(institution.id).and("isActive").equal(true).getItem();
            if (!factsheet) {
                newFactsheets.push({
                    "institution": institution,
                    "isActive": true
                });
            } else {
                newFactsheets.push({
                    "id": factsheet.id,
                    "institution": institution
                });
            }
        }

        let shortAgreement = {
            "id": agreement.id,
            "factsheets": newFactsheets
        };

        try {
            return await context.model(`MobilityAgreements`).save(shortAgreement);
        } catch (err) {
            console.log(err);
        }

    }

    const shouldFixFactsheets = (agreement) => {

        // filter for non local
        console.log("agreement.factsheets = ", agreement.factsheets);
        console.log("agreement.institutions = ", agreement.institutions);

        const partnerFactsheets = agreement.factsheets?.filter(f => !f?.institution.isLocal);
        const partnerInstitutions = agreement.institutions?.filter(i => !i.isLocal);

        console.log("partnerFactsheets = ", partnerFactsheets);

        if (partnerFactsheets.length > 0) { // δεν ειναι η πρώτη φορά που γινεται save το iia
            for (let f of agreement.factsheets) {
                if (f.institution.isLocal) {
                    continue;
                }
                const partnerInstitution = partnerInstitutions.find(inst => inst.id === f.institution.id);
                if (!partnerInstitution) {
                    // ο user αλλαξε τo institution του partner, σβησε το παλιο factsheet
                    return true;
                }
            }
        } else {
            // ειναι η πρωτη φορα που γινεται save το iia
            return true;
        }

        return false;
    }

    const approveAndNotifyPartner = async () => {
        const departmantalCoordinatorExistsForPartners = await checkLocalDepartmantalCoordinatorExists();
        if (!departmantalCoordinatorExistsForPartners) {
            alert("You have to provide a local departmental coordinator");
        }
        else {
            await saveIIA("Final");
            await setLoader(true);
            await approveIIA(agreement.id);
            redirectUserAfterChosenActionIsExecuted(agreement.id);
        }
       

    }

    const renderButtonsIro = () => {
        return (
            <div className="d-flex justify-content-center">
                {step > 1 ? <button className="btn btn-previous m-2" onClick={() => setStep(prevStep => prevStep - 1)}>Previous</button> : <></>}
                {step < 8 ? <button className="btn btn-next m-2" type="submit">Next</button> : <>
                    <button className="btn btn-primary m-2" disabled={(agreement?.status?.name === "Submitted" || agreement?.status?.name === "Final")} onClick={saveIIAAsDraft}>Save Draft</button>
                    <button className="btn btn-primary btn-success btn-submit m-2" disabled={agreement?.status?.name === "Final"} onClick={saveIIAAsSubmitted}>Submit</button>
                    <button className="btn btn-primary m-2" disabled={!(agreement?.status?.name === "Submitted" || agreement?.status?.name === "Final")} onClick={saveIIAAsFinalAndNotifyPartner}>Finalize and Notify Partner</button>
                    <button className="btn btn-primary m-2" disabled={isApproveButtonDisabled(agreement?.ewpStatus?.name, agreement?.partnerAgreementIdentifications, localIiaCnrList)} onClick={approveAndNotifyPartner}>Approve and Notify Partner</button></>}
            </div>
        );
    }

    const renderButtonsFaculty = () => {
        return (
            <div className="d-flex justify-content-center">
                {step > 1 ? <button className="btn btn-previous m-2" onClick={() => setStep(prevStep => prevStep - 1)}>Previous</button> : <></>}
                {step < 6 ? <button className="btn btn-next m-2" type="submit">Next</button> : <>
                    <button className="btn btn-primary m-2" disabled={(agreement?.status?.name === "Submitted" || agreement?.status?.name === "Final")} onClick={saveIIAAsDraft}>Save Draft</button>
                    <button className="btn btn-primary btn-success btn-submit m-2" disabled={agreement?.status?.name === "Final"} onClick={saveIIAAsSubmitted}>Submit</button>
                </>}
            </div>
        );
    }

    const handleStepIro = () => {
        if (step !== 8) {
            setStep(prevStep => prevStep + 1);
        }
    }

    const handleStepFaculty = () => {
        if (step !== 6) {
            setStep(prevStep => prevStep + 1);
        }
    }

    const renderSideNavIro = () => {
        return (<Nav variant="pills" className="flex-column" activeKey={"step_" + step}>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                          eventKey={"step_1"} 
                          onClick={() => mobilityID ? setStep(prevStep => 1) : {}}>
                            Agreement Details
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                          eventKey={"step_2"} 
                          disabled={mobilityID ? false : step < 2 || step == 9} 
                          onClick={() => mobilityID ? setStep(prevStep => 2) : {}}>
                            Contact Details of the Local Institution
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                          eventKey={"step_3"} 
                          disabled={mobilityID ? false : step < 3 || step == 9} 
                          onClick={() => mobilityID ? setStep(prevStep => 3) : {}}>
                            Contact Details of the Partner Institution
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"} >
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                          eventKey={"step_4"} 
                          disabled={mobilityID ? false : step < 4 || step == 9} 
                          onClick={() => mobilityID ? setStep(prevStep => 4) : {}}>
                            Student Mobility for Studies
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                          eventKey={"step_5"} 
                          disabled={mobilityID ? false : step < 5 || step == 9} 
                          onClick={() => mobilityID ? setStep(prevStep => 5) : {}}>
                            Student Mobility for Traineeships
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                          eventKey={"step_6"} 
                          disabled={mobilityID ? false : step < 6 || step == 9} 
                          onClick={() => mobilityID ? setStep(prevStep => 6) : {}}>
                            Staff Mobility for Teaching
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                          eventKey={"step_7"} 
                          disabled={mobilityID ? false : step < 7 || step == 9} 
                          onClick={() => mobilityID ? setStep(prevStep => 7) : {}}>
                            Staff Mobility for Training
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                          eventKey={"step_8"} 
                          disabled={mobilityID ? false : step < 8 || step == 9} 
                          onClick={() => mobilityID ? setStep(prevStep => 8) : {}}>
                            Comments
                </Nav.Link>
            </Nav.Item>
        </Nav>);
    }

    const renderSideNavFaculty = () => {
        return (<Nav variant="pills" className="flex-column" activeKey={"step_" + step}>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                          eventKey={"step_1"} 
                          onClick={() => mobilityID ? setStep(prevStep => 1) : {}}>
                            Agreement Details
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"}
                          eventKey={"step_2"}
                          disabled={mobilityID ? false : step < 2}
                          onClick={() => mobilityID ? setStep(prevStep => 2) : {}}>
                            Contact Details of the Partner Institution
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                    eventKey={"step_3"} 
                          disabled={mobilityID ? false : step < 3} 
                          onClick={() => mobilityID ? setStep(prevStep => 3) : {}}>
                    Student Mobility for Studies
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                    eventKey={"step_4"} 
                          disabled={mobilityID ? false : step < 4} 
                          onClick={() => mobilityID ? setStep(prevStep => 4) : {}}>
                    Student Mobility for Traineeships
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"} 
                    eventKey={"step_5"} 
                          disabled={mobilityID ? false : step < 5} 
                    onClick={() => mobilityID ? setStep(prevStep => 5) : {}}>
                    Staff Mobility for Teaching
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mobilityID ? " " : "no-cursor"}>
                <Nav.Link className={mobilityID ? " " : "no-cursor"}
                    eventKey={"step_6"}
                    disabled={mobilityID ? false : step < 6}
                    onClick={() => mobilityID ? setStep(prevStep => 6) : {}}>
                    Staff Mobility for Training
                </Nav.Link>
            </Nav.Item>

        </Nav>);
    }

    const switchCaseStepsFaculty = () => {
        switch (step) {
            case 1:
                return <Tab.Pane eventKey={"step_1"}>
                    <InitialAgreementDetails
                        iia={agreement}
                        setIia={(e) => {
                            setAgreement(e)
                            if (agreement?.institutions?.length >= 2 && step === 1) {
                                setPartnerDepartmentList(null);
                                setPartnerContactList(null);
                                setPartnerDepartmentandContactLists(agreement);
                            }
                        }}

                        handleStep={handleStepFaculty}
                        userService={props.userService}
                        countries={countries}
                        academicYears={academicYears}
                    >
                        {renderButtonsFaculty()}
                    </InitialAgreementDetails>
                </Tab.Pane>;
            case 2:
                return <Tab.Pane eventKey={"step_2"}>
                    <EditContactDetails
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        handleStep={handleStepFaculty}
                        departmentList={partnerDepartmentList}
                        setDepartmentList={(e) => setPartnerDepartmentList(e)}
                        contactList={partnerContactList}
                        setContactList={(e) => setPartnerContactList(e)}
                        isLocal={false}>
                        {renderButtonsFaculty()}
                    </EditContactDetails>
                </Tab.Pane>;
            case 3:
                return <Tab.Pane eventKey={"step_3"}>
                    <StudentMobility
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        localDepartmentList={localDepartmentList}
                        partnerDepartmentList={partnerDepartmentList}
                        handleStep={handleStepFaculty}
                        mobility="studentMobilitiesForStudies"
                        isced={isced}
                    >
                        {renderButtonsFaculty()}
                    </StudentMobility>
                </Tab.Pane>;
            case 4:
                return <Tab.Pane eventKey={"step_4"}>
                    <div />
                    <StudentMobility
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        localDepartmentList={localDepartmentList}
                        partnerDepartmentList={partnerDepartmentList}
                        handleStep={handleStepFaculty}
                        mobility="studentMobilitiesForTraineeship"
                        isced={isced}
                    >
                        {renderButtonsFaculty()}
                    </StudentMobility>
                </Tab.Pane>;
            case 5:
                return <Tab.Pane eventKey={"step_5"}>
                    <StaffMobility
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        localDepartmentList={localDepartmentList}
                        partnerDepartmentList={partnerDepartmentList}
                        handleStep={handleStepFaculty}
                        mobility="staffMobilitiesForTeaching"
                        isced={isced}
                    >
                        {renderButtonsFaculty()}
                    </StaffMobility>
                </Tab.Pane>;
            case 6:
                return <Tab.Pane eventKey={"step_6"}>
                    <div />
                    <StaffMobility
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        localDepartmentList={localDepartmentList}
                        partnerDepartmentList={partnerDepartmentList}
                        handleStep={handleStepFaculty}
                        mobility="staffMobilitiesForTraining"
                        isced={isced}
                    >
                        {renderButtonsFaculty()}
                    </StaffMobility>
                </Tab.Pane>;
            default:
                return <h1>No content</h1>
        }

    }

    const switchCaseStepsIro = () => {
        switch (step) {
            case 1:
                return <Tab.Pane eventKey={"step_1"}>
                    <InitialAgreementDetails
                        iia={agreement}
                        setIia={(e) => {
                            setAgreement(e)
                            if (agreement?.institutions?.length >= 2 && step === 1) {
                                setPartnerDepartmentList(null);
                                setPartnerContactList(null);
                                setPartnerDepartmentandContactLists(agreement);
                            }
                        }}
                        handleStep={handleStepIro}
                        userService={props.userService}
                        countries={countries}
                        academicYears={academicYears}
                    >
                        {renderButtonsIro()}
                    </InitialAgreementDetails>
                </Tab.Pane>;
            case 2:
                return <Tab.Pane eventKey={"step_2"}>
                    <EditContactDetails
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        handleStep={handleStepIro}
                        departmentList={localDepartmentList}
                        setDepartmentList={(e) => setLocalDepartmentList(e)}
                        contactList={localContactList}
                        setContactList={(e) => setLocalContactList(e)}
                        isLocal={true}>
                        {renderButtonsIro()}
                    </EditContactDetails>
                </Tab.Pane>;
            case 3:
                return <Tab.Pane eventKey={"step_3"}>
                    <div />
                    <EditContactDetails
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        handleStep={handleStepIro}
                        departmentList={partnerDepartmentList}
                        setDepartmentList={(e) => setPartnerDepartmentList(e)}
                        contactList={partnerContactList}
                        setContactList={(e) => setPartnerContactList(e)}
                        isLocal={false}>
                        {renderButtonsIro()}
                    </EditContactDetails>
                </Tab.Pane>;
            case 4:
                return <Tab.Pane eventKey={"step_4"}>
                    <StudentMobility
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        localDepartmentList={localDepartmentList}
                        partnerDepartmentList={partnerDepartmentList}
                        handleStep={handleStepIro}
                        mobility="studentMobilitiesForStudies"
                        isced={isced}
                    >
                        {renderButtonsIro()}
                    </StudentMobility>
                </Tab.Pane>;
            case 5:
                return <Tab.Pane eventKey={"step_5"}>
                    <div />
                    <StudentMobility
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        localDepartmentList={localDepartmentList}
                        partnerDepartmentList={partnerDepartmentList}
                        handleStep={handleStepIro}
                        mobility="studentMobilitiesForTraineeship"
                        isced={isced}
                    >
                        {renderButtonsIro()}
                    </StudentMobility>
                </Tab.Pane>;
            case 6:
                return <Tab.Pane eventKey={"step_6"}>
                    <StaffMobility
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        localDepartmentList={localDepartmentList}
                        partnerDepartmentList={partnerDepartmentList}
                        handleStep={handleStepIro}
                        mobility="staffMobilitiesForTeaching"
                        isced={isced}
                    >
                        {renderButtonsIro()}
                    </StaffMobility>
                </Tab.Pane>;
            case 7:
                return <Tab.Pane eventKey={"step_7"}>
                    <div />
                    <StaffMobility
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        localDepartmentList={localDepartmentList}
                        partnerDepartmentList={partnerDepartmentList}
                        handleStep={handleStepIro}
                        mobility="staffMobilitiesForTraining"
                        isced={isced}
                    >
                        {renderButtonsIro()}
                    </StaffMobility>
                </Tab.Pane>;
            case 8:
                return <Tab.Pane eventKey={"step_8"}>
                    <EditIIAComments
                        iia={agreement}
                        setIia={(e) => setAgreement(e)}
                        commentsText={agreement.comments}
                        handleStep={handleStepIro}
                    >
                        {renderButtonsIro()}
                    </EditIIAComments>
                </Tab.Pane>;
            default:
                return <h1>No content</h1>
        }

    }
    if (loader || agreement === null) {
        return (<div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Loader
                className="spinner mx-auto"
                type="TailSpin"
                color="#3A435E"
                height={70}
                width={70}
            />
        </div>);
    }
    else {
        return (
            <Tab.Container
                id="left-tabs"
                activeKey={"step_" + step}
            //    onSelect={(k) => props.location.pathname.includes("edit") && setStep(parseInt(k.replace("step_", "")))}
            >
                <Row>
                    <Col sm={3}>
                        <Restricted to="iia_edit_iro" fallback={renderSideNavFaculty()}>
                            {renderSideNavIro()}
                        </Restricted>
                    </Col>
                    <Col sm={9}>
                        <Restricted to="iia_edit_iro" fallback={switchCaseStepsFaculty()}>
                            {switchCaseStepsIro()}
                        </Restricted>
                    </Col>
                </Row>
            </Tab.Container>
        );
    }
}
export default Form
