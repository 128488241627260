import { reducer } from '../reducers/iiaQueryReducer';
import { useEffect, useReducer, useRef, useCallback } from 'react';
import { ReactDataContext } from '@themost/react';
import {
    SET_PAGE_ACTION,
    UPDATE_MULTIPLE_VALUES_ACTION
} from '../reducers/iiaQueryReducer';

export const defaultState = {
    agreements: [],
    page: 0,
    total: 0,
    loading: true
}

/**
 * Used to query for IIA's using the filters provided
 * 
 * @typedef {{
 *  agreements: [Object],
 *  page: Number,
 *  total: Number,
 *  loading: Boolean
 * }} ReturnType
 * 
 * @typedef {{
 *      startYearSelected,
 *      endYearSelected, 
 *      countrySelected, 
 *      institutionSelected, 
 *      agreementIdSelected,
 *      ewpIdSelected,
 *      statusSelected,
 *      ewpStatusSelected,
 *      departmentSelected, 
 *      departmentalCoordinatorSelected,
 *      countriesList,
 *      ewpStatusesList,
 *      departments,
 *      academicYears,
 *      institutionsList,
 *      departmentalCoordinatorsList,
 *      isDeletedSelected
 * }} FiltersState
 * 
 * @typedef {{
 *      state: FiltersState,
 *      setAcademicYears: function,
 *      setStartYearSelected: function,
 *      setEndYearSelected: function,
 *      setCountrySelected: function,
 *      setInstitutionSelected: function,
 *      setAgreementIdSelected: function,
 *      setEwpIdSelected: function,
 *      setStatusSelected: function,
 *      setEwpStatusSelected: function,
 *      setDepartmentSelected: function,
 *      setDepartmentalCoordinatorSelected: function,
 *      setCountriesList: function,
 *      setEwpStatusesList: function,
 *      setDepartments: function,
 *      setAcademicYears: function,
 *      setInstitutionsList: function,
 *      setDepartmentalCoordinatorsList: function,
 *      setIsDeletedSelected: function,
 *      updateMultipleValues: function
 * }} Filter
 * 
 * @param {Filter} filters - The filters associated with the IIA query
 * @param {Object} userServce - The service used to authenticate the user
 * 
 * @return {ReturnType} Object containing the agreements the query returned, the current page 0-indexed number, the total amount of agreements the same query can return
 */
export const useIiaQuery = (filters, userService) => {
    const [state, dispatch] = useReducer(reducer, defaultState); 

    const context = new ReactDataContext(process.env.REACT_APP_API_SERVER);
    context.setBearerAuthorization(userService.getToken());

    const mountedCountrySelected = useRef(false);
    const mountedRestOptions = useRef(false);
    const mountedPage = useRef(false);

    const setPage = useCallback((page) => {
        dispatch({type: SET_PAGE_ACTION, payload: { page }});
    }, []);

    const updateMultipleValues = useCallback((data) => {
        dispatch({type: UPDATE_MULTIPLE_VALUES_ACTION, payload: data})
    }, []);

    const fetchDepartmentalCoordinators = async () => {
      try {
          const mobilityContactsResult = await context.model(`MobilityContacts`).where('department').equal(filters.state.departmentSelected).take(-1).getItems()
          filters.setDepartmentalCoordinatorsList(mobilityContactsResult.filter(i => !i.email.includes("test")))
      } catch (err) { 
          console.log(err) 
      }
    }

    const fetchAgreements = async (pageNumber) => {
      let fields = [];
      
      if (filters.state.statusSelected) {
        fields.push({
          "key": "status",
          "value": filters.state.statusSelected
        })
      }

      if (filters.state.ewpStatusSelected) {
        fields.push({
          "key": "ewpStatus",
          "value": filters.state.ewpStatusSelected
        })
      }
      
      if (filters.state.countrySelected) {
        fields.push({
          "key": "institutions/country",
          "value": filters.state.countrySelected
        })
      }

      if (filters.state.institutionSelected) {
        fields.push({
          "key": "institutions/id",
          "value": filters.state.institutionSelected
        })
      }
  
      if (filters.state.startYearSelected) {
        fields.push({
          "key": "startYear",
          "value": filters.state.startYearSelected
        })
      }
  
      if (filters.state.endYearSelected) {
        fields.push({
          "key": "endYear",
          "value": filters.state.endYearSelected
        })
      }

      if (filters.state.agreementIdSelected) {
        fields.push({
          "key": "id",
          "value": filters.state.agreementIdSelected
        })
      }

      if (filters.state.ewpIdSelected) {
        fields.push({
          "key": "partnerAgreementIdentifications/iiaId",
          "value": filters.state.ewpIdSelected
        })
      }

      if (filters.state.localFinalizedSelected) {
        console.log(filters.state.localFinalizedSelected)
        fields.push({
          "key": "localStatus",
          "value": filters.state.localFinalizedSelected
        })
      }

      if (filters.state.isDeletedSelected) {
        fields.push({
          "key": "isDeleted",
          "value": filters.state.isDeletedSelected
        })
      }

      if (filters.state.departmentSelected) {
          fields.push({
              "key": "departmentalCoordinatorContacts/department",
              "value": filters.state.departmentSelected
          })

          if (filters.state.departmentalCoordinatorSelected) {
              // the user selected a different coordinator from the currently selected department
              fields.push({
                  "key": "departmentalCoordinatorContacts/id",
                  "value": filters.state.departmentalCoordinatorSelected
              })
          } else if(pageNumber === 0) {
              // the user selected a different department, fetch the coordinators of the selected department in the background
              fetchDepartmentalCoordinators();
          }
      }

      let query = context.model(`MobilityAgreements`).asQueryable();
      
      for (let i = 0; i < fields.length; i++) {
        if (i === 0) {
          query = query.where(fields[i].key).equal(fields[i].value)
        } else {
          query = query.and(fields[i].key).equal(fields[i].value)
        }
      }
      
      try {
        const agreementsResult = await query.select('id','startYear','endYear','institutions','departmentalCoordinatorContacts','status','ewpStatus','dateCreated').take(10).skip(state.page * 10).getList();
        console.log(agreementsResult)
        updateMultipleValues({
          agreements: agreementsResult.value,
          loading: false,
          total: agreementsResult.total,
          page: pageNumber
        }) 
      } catch (err) {
        console.log(err);
      }
    }
    
    // runs on first mount
    useEffect(() => {
        (async () => {
            try {
                let countriesResult, authDepartmentsResult, academicYearsResult, ewpStatusesResult;
              let query = context.model(`MobilityAgreements`).asQueryable().where("isDeleted").equal("0");
                let agreementsResult;

                await Promise.all([
                  countriesResult = await context.model(`MobilityCountries`).asQueryable().take(-1).getItems(),
                  ewpStatusesResult = await context.model(`MobilityEwpStatuses`).asQueryable().take(-1).getItems(),
                  authDepartmentsResult = await context.model(`MobilityDepartments`).where('institution/isLocal').equal(true).take(-1).getItems(),
                  academicYearsResult = await context.model(`AcademicYears`).where('id').greaterOrEqual(2014).take(-1).getItems(),
                  agreementsResult = await query.select('id','startYear','endYear','institutions','departmentalCoordinatorContacts','status','ewpStatus','dateCreated').take(10).skip(state.page * 10).getList()
                ])

                filters.updateMultipleValues({
                    countriesList: countriesResult,
                    departments: authDepartmentsResult,
                    academicYears: academicYearsResult,
                    ewpStatusesList: ewpStatusesResult
                })

                updateMultipleValues({
                    agreements: agreementsResult.value,
                    total: agreementsResult.total,
                    loading: false,
                    page: 0,
                })
            } catch(err) {
                console.log(err);
            }
        })()
    }, [])
    
    // runs when the user selects a country
    useEffect(() => {
        if(mountedCountrySelected.current === false) {
            mountedCountrySelected.current = true;
            return;
        }

        (async () => {
            try {
                let institutionsResult;
                
                await Promise.all([
                  institutionsResult = await context.model(`MobilityInstitutions`).where('country').equal(filters.state.countrySelected).take(-1).getItems(),
                  await fetchAgreements(0) 
                ])

                filters.setInstitutionsList(institutionsResult)
            } catch (err) {
                console.log(err)
            }
        })()
    }, [filters.state.countrySelected])

    // runs when the user changes the rest of the options
    useEffect(() => {
        if(mountedRestOptions.current === false) {
            mountedRestOptions.current = true;
            return;
        }

        (async () => {
            fetchAgreements(0);
          })()
    }, [
        filters.state.statusSelected,
        filters.state.ewpStatusSelected,
        filters.state.institutionSelected, 
        filters.state.agreementIdSelected,
        filters.state.ewpIdSelected,
        filters.state.startYearSelected, 
        filters.state.endYearSelected, 
        filters.state.departmentalCoordinatorSelected,
        filters.state.departmentSelected,
      filters.state.localFinalizedSelected,
      filters.state.isDeletedSelected
    ]);

    // runs when the user changes page
    useEffect(() => {
      if(mountedPage.current === false) {
        mountedPage.current = true;
        return;
      }

    (async () => {
        fetchAgreements(state.page)
      })()
    }, [state.page]);

    return {agreements: state.agreements, loading: state.loading, page: state.page, total: state.total, setPage};
}